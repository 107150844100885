import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MessagesSuccessComponent } from './messages-success.component';
import { SharedModule } from '../../../shared.module';

@NgModule({
  imports: [
    SharedModule,
    MatIconModule
  ],
  declarations: [MessagesSuccessComponent],
  exports: [MessagesSuccessComponent]
})
export class MessagesSuccessModule { }
