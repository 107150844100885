import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable()
export class BaseService implements OnDestroy {

    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    private subscriptions: Subscription[] = new Array<Subscription>();

    // -----------------------------------------------------------------------------------------------------
    // @ LIFE CYCLE HOOKS
    // -----------------------------------------------------------------------------------------------------

    ngOnDestroy() {
        this.removeSubscriptions();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    /**
     * Unsubscribes from any open subscriptions in the subscriptions array in ngOnDestroy
     */
    public removeSubscriptions() {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PROTECTED METHODS
    // -----------------------------------------------------------------------------------------------------

    /**
     * Adds a subscriptions so it can be deleted in ngOnDestroy
     *
     * @param subscription The subscription that should be added
     * BaseComponent
     */
    protected addSubscription(subscription: Subscription) {
        this.subscriptions.push(subscription);
    }

    protected cancelAllSubscriptions() {

        if (this.subscriptions.length === 0) {
            return;
        }

        this.subscriptions.forEach(s => {
            s.unsubscribe();
        });
        this.subscriptions.slice(0);
    }

}
